var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入名称' },
                { max: 64, message: '超出最大长度(64)' },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入名称' },\n                { max: 64, message: '超出最大长度(64)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"生产线"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'production_line',
            {
              rules: [{ required: true, message: '请选择生产线' }],
            },
          ]),expression:"[\n            'production_line',\n            {\n              rules: [{ required: true, message: '请选择生产线' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"a"}},[_vm._v("名创 A")]),_c('a-select-option',{attrs:{"value":"b"}},[_vm._v("名创 B")]),_c('a-select-option',{attrs:{"value":"c"}},[_vm._v("名创 C")])],1)],1),_c('a-form-item',{attrs:{"label":"道口号"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'number',
            {
              rules: [{ required: true, message: '请输入道口号' }],
            },
          ]),expression:"[\n            'number',\n            {\n              rules: [{ required: true, message: '请输入道口号' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"con"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['con_number', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]),expression:"['con_number', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"最大分拣数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['max_sorting_quantity']),expression:"['max_sorting_quantity']"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"最大分拣重量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['max_sorting_weight']),expression:"['max_sorting_weight']"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }